<template>
	<div class="page-container md-layout-row">
		<md-app :md-mode="isMobile ? `` : `reveal`">
			<md-app-toolbar md-elevation="1" class="md-transparent" v-if="isMobile">
				<md-button v-if="showMenuIcon" class="md-icon-button" @click="menuVisible = !menuVisible">
					<md-icon>menu</md-icon>
				</md-button>
				<span class="md-title">
					Pedrali App Backend
				</span>
				<div class="md-toolbar-section-end">
					<md-menu>
						<md-button class="md-icon-button" md-menu-trigger>
							<md-icon>person</md-icon>
						</md-button>

						<md-menu-content>
							<md-menu-item :to="{ name: 'login.page' }">
								<md-icon>logout</md-icon>
								<span>{{ $t('common.logout') }}</span>
							</md-menu-item>
						</md-menu-content>
					</md-menu>
				</div>
			</md-app-toolbar>

			<md-app-drawer class="main_menu" :md-active.sync="menuVisible" md-permanent="clipped">
				<!-- hide for mobile -->
				<md-card class="md-elevation-0" v-if="!isMobile">
					<md-card-media-cover>
						<md-card-media md-ratio="16:9">
							<img src="@/assets/logo.svg" alt="Pedrali">
						</md-card-media>
					</md-card-media-cover>
				</md-card>
				<md-list>
					<!--<md-list-item :to="{ name: 'dashboard.page' }" :class="isActive('dashboard')">
						<md-icon>dashboard</md-icon>
						<span class="md-list-item-text">{{ $t('menu.dashboard') }}</span>
					</md-list-item>-->
					<!--<md-list-item :to="{ name: 'editorial.page' }">
						<md-icon>mode</md-icon>
						<span class="md-list-item-text">{{ $t('menu.editorial') }}</span>
					</md-list-item>-->

					<md-list-item :to="{ name: 'users.page' }" :class="isActive('user')">
						<md-icon>group</md-icon>
						<span class="md-list-item-text">{{ $t('menu.users') }}</span>
					</md-list-item>
					<md-list-item :to="{ name: 'documents.page' }" :class="isActive('documents')">
						<md-icon>source</md-icon>
						<span class="md-list-item-text">{{ $t('menu.documents') }}</span>
					</md-list-item>
          <md-list-item :to="{ name: 'statistics.page' }" :class="isActive('statistics')">
            <md-icon>bar_chart</md-icon>
            <span class="md-list-item-text">{{ $t('menu.statistics') }}</span>
          </md-list-item>
					<!--<md-list-item :to="{ name: 'requests.page' }" :class="isActive('requests')">
						<md-icon>group</md-icon>
						<span class="md-list-item-text">{{ $t('menu.requests') }}</span>
					</md-list-item>-->
					<!--<md-list-item :to="{ name: 'showroom.page' }" :class="isActive('showroom')">
						<md-icon>group</md-icon>
						<span class="md-list-item-text">{{ $t('menu.showroom') }}</span>
					</md-list-item>-->
					<!--<md-list-item :to="{ name: 'notifications.page' }" :class="isActive('notifications')">
						<md-icon>mode_comment</md-icon>
						<span class="md-list-item-text">{{ $t('menu.notifications') }}</span>
					</md-list-item>-->
					<md-list-item>
					</md-list-item>
					<md-list-item :to="{ name: 'login.page' }">
						<md-icon>logout</md-icon>
						<span class="md-list-item-text">{{ $t('common.logout') }}</span>
					</md-list-item>
				</md-list>
			</md-app-drawer>
			<md-app-content class="app-content">
				<router-view/>
				<md-snackbar md-position="left" :md-duration="2000" :md-active.sync="showSaveSuccess" md-persistent>
					<span>
						{{ $t('common.save_success') }}
					</span>
					<md-button class="md-primary" @click="showSaveSuccess = false">
						{{ $t('common.close') }}
					</md-button>
				</md-snackbar>

				<md-snackbar md-position="left" :md-duration="2000" :md-active.sync="showDeleteSuccess" md-persistent>
					<span>
						{{ $t('common.delete_success') }}
					</span>
					<md-button class="md-primary" @click="showSaveSuccess = false">
						{{ $t('common.close') }}
					</md-button>
				</md-snackbar>

				<md-snackbar md-position="left" :md-duration="2000" :md-active.sync="showError" md-persistent>
					<span>
						{{ $t('common.error') }}<br>
						{{ errors.join(",")}}
					</span>
					<md-button class="md-primary" @click="showSaveSuccess = false">
						{{ $t('common.close') }}
					</md-button>
				</md-snackbar>
			</md-app-content>
		</md-app>

	</div>
</template>

<script>
import {EventBus} from "@/events-bus";

export default {
	name: 'MainLayout',
	data () {
		return {
			isMobile: false,
			menuVisible: false,
			showMenuIcon: true,
			showSaveSuccess: false,
			showDeleteSuccess: false,
			showError: false,
			errors: []
		}
	},
	methods: {
		onResize () {
			this.isMobile = this.$helpers.isMobile();
			if (this.isMobile) {
				this.menuVisible = false;
				this.showMenuIcon = true;
			} else {
				this.menuVisible = true;
				this.showMenuIcon = false;
			}
		},
		isActive (path) {
			if (this.$route.path.indexOf(`/${path}`) === 0) {
				return `md-list-item-container-active`
			}
			return ``
		}
	},
	mounted () {

		this.isMobile = this.$helpers.isMobile();
		//this.onResize();
		//window.addEventListener('resize', this.onResize, {passive: true})

		// Events
		EventBus.$on('save-success', () => {
			this.showSaveSuccess = true;
		})
		EventBus.$on('delete-success', () => {
			this.showDeleteSuccess = true;
		})
		EventBus.$on('error', (errors) => {
			this.errors = [errors];
			if(Array.isArray(errors)) {
				this.errors = [];
				errors.forEach(e => {
					this.errors.push(this.$t(`common.errors.${e}`))
				})
			}
			this.showError = true;
		})
	},
	beforeDestroy () {
		if (typeof window !== 'undefined') {
			window.removeEventListener('resize', this.onResize, {passive: true})
		}
	}
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.md-app {
	height: 100vh;
}

.md-drawer {
	width: 250px;
	max-width: calc(100vw - 125px);
	background: $primary;
}
</style>
