<template>
	<div v-if="documents" class="tree_documents">
		<template v-if="level == 1">
			<div class="tree_documents__head tree_documents__row">
				<div class="box"></div>
				<div class="files">
					<div class="files__info">
						Permessi
					</div>
					<div class="files__info">
						Tipo
					</div>
					<div class="files__info">
						Dimensione
					</div>
					<!--<div class="files__info">
						Data
					</div>-->
				</div>
			</div>
		</template>

		<div v-for="d in documents" v-bind:key="d.id" :class="`tree_documents__row ${d.open ? `active` : ``}`">
			<div class="box">
				<!-- sign -->
				<span v-if="level > 1" :style="`display: block; width: ${30*level -1}px`"></span>
				<span class="box__sign" v-if="d.folder">
					<div @click="toggleFolder(d)">
						<span class="minus" v-if="d.open">-</span>
						<span class="plus" v-else>+</span>
					</div>
				</span>

				<!-- name -->
				<span class="box__label">
					<!--<span :style="`display: block; width: ${30*level -1}px`"></span>-->
					{{ d.name }}
				</span>
			</div>

			<!-- table -->
			<div class="files">
				<div class="files__info">
					<md-button class="md-dense md-raised md-primary" @click="eventOpenPermission(d)" v-if="d.folder">
						Permessi ({{ (d.permissions) ? d.permissions.length : 0 }})
					</md-button>
				</div>
				<div class="box__label" style="font-size: 12px; line-height: 14px">
					<span style="padding-right: 20px" v-if="d.file_permissions" v-html="htmlFilePermissions(d.file_permissions, 'it')"></span><br>
					<span v-if="d.file_permissions" v-html="htmlFilePermissions(d.file_permissions, 'en')"></span>
				</div>
				<div class="files__info" v-if="!d.folder">
					<md-button class="md-dense md-raised" @click="showPermissions(d)">
						Scarica permessi
					</md-button>
				</div>
				<div class="files__info">
					<span v-if="!d.folder">{{ getExtensionByMime(d.mime) }}</span>
				</div>
				<div class="files__info">
					<span v-if="!d.folder">{{ getSize(d.size) }}</span>
				</div>
				<!--<div class="files__info">
					<span v-if="!d.folder">{{ getDate(d.ts) }}</span>
				</div>-->
			</div>

			<!-- recursion -->
			<template v-if="d.open">
				<TreeDocuments :documents="d.children" :roles="roles" :langs="langs" :level="level + 1"></TreeDocuments>
			</template>
		</div>
	</div>
</template>
<script>
import moment from "moment";
import { EventBus } from '../events-bus';

export default {
	name: 'TreeDocuments',
	props: {
		documents: {
			required: true
		},
		roles: {
			required: true
		},
		langs: {
			required: true
		},
		level: {
			required: false,
			default: 1
		}
	},
	methods: {
		toggleFolder (d) {
			d.open = d.open || false;
			d.open = !d.open;
			this.$forceUpdate();
		},
		getChildren (items, checked) {
			(items || []).forEach(i => {
				i.checked = checked;
				this.getChildren(i.children, checked)
			})
		},
		checkUpdate () {
			this.$forceUpdate();
		},
		showPermissions(item) {
			item.file_permissions = [];
			this.service.documents.showFilePermissions(item.id)
				.then(permissions => {
					item.file_permissions = permissions;
					this.$forceUpdate();
				})
		},
		htmlFilePermissions(permissions, lang) {
			let html = [];
			permissions.data.filter((p) => {
				return p.lang == lang
			}).forEach(p => {
				html.push(p.label);
			})
			return `<strong>${lang} (${html.length})</strong>: ${html.join(", ")}`;
		},
		eventOpenPermission (item) {
			EventBus.$emit('openPermission', item)
		},
		getExtensionByMime (mime) {
			return mime;
		},
		getDate (ts) {
			return moment(ts * 1000).format("DD/MM/YYYY hh:mm")
		},
		getSize (bytes) {
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			if (bytes == 0) return '0 Byte';
			const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
			return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
		}
	}
}
</script>
<style lang="scss">
@import "@/assets/scss/variables";

.tree_documents {
	width: 100%;

	&__head {
		.files {
			&__info {
				padding: 10px 6px;
				font-weight: 700;
			}
		}
	}

	&__row {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;
		border-bottom: 1px solid rgba($primary, 0.5);

		&.active {
			background: rgba($primary, 0.2);
		}

		.box {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			width: 40%;

			&__sign {
				display: block;
				width: 15px;
				height: 15px;
				font-size: 1rem;
				font-weight: 700;
				line-height: 1;
				cursor: pointer;

				span.plus {
					border-radius: 10px;
					padding: 0 5px;
					background: $primary;
				}

				span.minus {
					border-radius: 10px;
					padding: 0 6px;
					background: $primary;
				}
			}

			&__label {
				display: flex;
				width: max-content;
				padding-left: 10px;
			}

		}

		.files {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: nowrap;
			width: 60%;

			&__info {
				width: 100%;
				text-align: center;
			}
		}
	}
}

/*
.tree_documents {
	&__head {
		.permissions {
			&__info {
				width: max-content;
				flex-wrap: wrap;
				position: relative;
				padding: 10px 6px;
			}
		}
	}

	&__row {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;
		border-bottom: 1px solid $primary;

		&.active {
			background: rgba($primary, 0.3);
		}
	}

	.tree_content {
		&:not(.level__1) {
			background: yellow;
			padding-left: 20px;
		}
	}

	.box {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 600px;

		&__sign {
			display: block;
			width: 15px;
			height: 15px;
			font-size: 1rem;
			font-weight: 700;
			line-height: 1;
			cursor: pointer;

			span {
				padding: 0 5px;
				background: $primary;
			}
		}

		&__label {
			display: flex;
			width: max-content;
			padding-right: 10px;
		}
	}

	.permissions {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: calc(100% - 600px);

		&__info {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			align-items: center;
			position: relative;
			padding: 10px 6px;
		}
	}
}*/
</style>
