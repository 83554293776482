import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from "@/views/pages/Login";
import MainLayout from "@/views/layouts/MainLayout";
import Documents from "@/views/pages/Documents";
import Users from "@/views/pages/Users";
import User from "@/views/pages/User";
import Notifications from "@/views/pages/Notifications";
import Editorial from "@/views/pages/Editorial";
import Requests from "../views/pages/Requests";
import Showroom from "../views/pages/Showroom";
import UserImport from "../views/pages/UserImport";
import Statistics from "@/views/pages/Statistics.vue";
import UsersActivity from "@/views/pages/UsersActivity.vue";
import DocumentsActivity from "@/views/pages/DocumentsActivity.vue";

Vue.use(VueRouter);

const guardAuthService = async (to, from, next) => {
    try {
        let token = localStorage.getItem('token');
        console.log(`Token`, token);
        if (token && token !== '') {
            next();
        } else {
            next({
                name: 'login.page',
            });
        }
    } catch (e) {
        next({
            name: 'login.page',
        });
    }
};

const routes = [
    {
        path: '/login',
        name: 'login.page',
        component: Login,
    },
    {
        path: '/',
        component: MainLayout,
        children: [/*{
		path: '/dashboard',
		name: 'dashboard.page',
		component: Dashboard,
		beforeEnter (to, from, next) {
			guardAuthService(to, from, next);
		}
	},*/
            {
                path: '/documents',
                name: 'documents.page',
                component: Documents,
                beforeEnter(to, from, next) {
                    guardAuthService(to, from, next);
                },
            },
            {
                path: '/editorial',
                name: 'editorial.page',
                component: Editorial,
                beforeEnter(to, from, next) {
                    guardAuthService(to, from, next);
                },
            },
            {
                path: '/notifications',
                name: 'notifications.page',
                component: Notifications,
                beforeEnter(to, from, next) {
                    guardAuthService(to, from, next);
                },
            },
            {
                path: '/users',
                name: 'users.page',
                component: Users,
                beforeEnter(to, from, next) {
                    guardAuthService(to, from, next);
                },
            },
            {
                path: '/users/import/tracciato',
                name: 'user.import',
                component: UserImport,
                beforeEnter(to, from, next) {
                    guardAuthService(to, from, next);
                },
            },
            {
                path: '/user/:id',
                name: 'user.page',
                component: User,
                beforeEnter(to, from, next) {
                    guardAuthService(to, from, next);
                },
            },
            {
                path: '/requests',
                name: 'requests.page',
                component: Requests,
                beforeEnter(to, from, next) {
                    guardAuthService(to, from, next);
                },
            },
            {
                path: '/showroom',
                name: 'showroom.page',
                component: Showroom,
                beforeEnter(to, from, next) {
                    guardAuthService(to, from, next);
                },
            },
            {
                path: '/statistics',
                name: 'statistics.page',
                component: Statistics,
                beforeEnter(to, from, next) {
                    guardAuthService(to, from, next);
                },
            },
            {
                path: '/statistics/activity/users',
                name: 'usersActivity.page',
                component: UsersActivity,
                props: route => ({action: route.query.action}),
                beforeEnter(to, from, next) {
                    guardAuthService(to, from, next);
                },
            },
            {
                path: '/statistics/activity/documents',
                name: 'documentsActivity.page',
                component: DocumentsActivity,
                props: route => ({action: route.query.action}),
                beforeEnter(to, from, next) {
                    guardAuthService(to, from, next);
                },
            },
            {
                path: '*',
                redirect: '/users',
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL || ``,
    routes,
});

export default router;
