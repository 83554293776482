import axios from 'axios';

const API_URL = (process.env.VUE_APP_API_URL !== undefined && process.env.VUE_APP_API_URL !== "") ? process.env.VUE_APP_API_URL : '{{ VUE_APP_API_URL }}';

export default {
    install: function (Vue) {
        // global $http object
        Object.defineProperty(Vue.prototype, '$http', {value: axios});
        // axios settings
        axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
        /**
         * Creazione della risposta
         * @param response
         * @param resolve
         * @param reject
         * @param token
         * @param redirectUnauthorized
         */
        const buildResponse = (response, resolve, reject) => {
            let data = response.data || "";
            let status = response.status || null;
            if (response && response.status && status == 200) {
                resolve(data);
            }
            if (response && data.messages && status != 200) {
                if (data.messages.err == "Algorithm not allowed") {
                    localStorage.clear();
                    window.location.href = "/";
                }
                if (data.messages && Array.isArray(data.messages) && data.messages[0] && data.messages[0] == "session_expired") {
                    console.log(`Sessione finita`);
                    localStorage.clear();
                    window.location.href = "/";
                }
                reject(data);
            }
            reject({
                messages: ['api-general-error'],
            });
        };
        /**
         *
         */
        const getBaseHeaders = () => {
            let token = localStorage.getItem('token');
            return {
                validateStatus: () => {
                    return true;
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
        };
        //
        // definizione delle api
        //
        const api = {
            /**
             * @param email
             * @param password
             * @returns {Promise<any>}
             */
            login(email, password) {
                return new Promise((resolve, reject) => {
                    localStorage.removeItem('token');
                    axios.post(`${API_URL}/user/signin`, {
                        username: email.trim(),
                        password: password.trim(),
                    }, {
                        validateStatus: () => {
                            return true;
                        },
                    })
                        .catch(() => {
                            reject({
                                messages: ['login-error'],
                            });
                        })
                        .then((result) => {
                            buildResponse(result, (r) => {
                                if (r && r.user && r.user.role && r.user.role == "admin") {
                                    localStorage.setItem('token', r.token);
                                    resolve(r);
                                } else {
                                    reject({
                                        messages: ['login-error'],
                                    });
                                }
                            }, reject);
                        });
                });
            },
            /**
             * @param resource
             * @param data
             * @param params
             * @returns {Promise<unknown>}
             */
            get(resource, data = {}, params = {}) {
                let config = getBaseHeaders();
                if (data) {
                    config.data = data;
                }
                if (params) {
                    config.params = params;
                }

                return new Promise((resolve, reject) => {
                    axios.get(`${API_URL}${resource}`, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error,
                            });
                        })
                        .then((result) => {
                            buildResponse(result, resolve, reject);
                        });
                });
            },
            /**
             * Nuovo inserimento di una risorsa
             * @param resource
             * @param data
             * @param extraConfig
             * @returns {Promise<unknown>}
             */
            post(resource, data, extraConfig = {}) {
                let config = {
                    ...getBaseHeaders(),
                    ...extraConfig,
                };

                return new Promise((resolve, reject) => {
                    axios.post(`${API_URL}${resource}`, data, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error,
                            });
                        }).then((result) => {
                        buildResponse(result, resolve, reject);
                    });
                });
            },
            /**
             * Modifica di una risorsa
             * @param resource
             * @param data
             * @returns {Promise<unknown>}
             */
            put(resource, data) {
                let config = getBaseHeaders();
                return new Promise((resolve, reject) => {
                    axios.put(`${API_URL}${resource}`, data, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error,
                            });
                        }).then((result) => {
                        buildResponse(result, resolve, reject);
                    });
                });
            },
            /**
             * Eliminazione di una risorsa
             * @param resource
             * @param data
             * @returns {Promise<unknown>}
             */
            delete(resource, data) {
                let config = getBaseHeaders();
                if (data) {
                    config.data = data;
                }
                return new Promise((resolve, reject) => {
                    axios.delete(`${API_URL}${resource}`, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error,
                            });
                        })
                        .then((result) => {
                            buildResponse(result, resolve, reject);
                        });
                });
            },
        };
        Vue.prototype.$api = api;
    },
};
