<template>
	<div>
		<div class="md-layout md-layout-login md-gutter md-alignment-center-center">
			<div class="md-layout-item md-xlarge-size-33 md-large-size-33 md-medium-size-33 md-small-size-50 md-xsmall-size-100">
				<md-card>
					<md-card-media-cover>
						<md-card-media md-ratio="16:9">
							<img src="@/assets/logo.svg" alt="Pedrali">
						</md-card-media>
					</md-card-media-cover>
					<md-card-content class="">
						<LoginForm></LoginForm>
					</md-card-content>
				</md-card>
			</div>
		</div>
	</div>
</template>

<script>
import LoginForm from "@/components/LoginForm";
export default {
	name: 'Login',
	components: {LoginForm},
	methods: {
		init () {
			localStorage.removeItem('token');
		},
		onResize() {

		}
	},
	mounted () {
		this.init();
		this.onResize();
		window.addEventListener('resize', this.onResize, {passive: true})
	}
}
</script>

<style lang="scss">
</style>