import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './registerServiceWorker'
import i18n from './plugins/i18n.plugin'
import HelpersPlugin from './plugins/helpers.plugin'
import ApiPlugin from './plugins/api.plugin';
import ServicePlugin from './plugins/service.plugin';

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/it';

Vue.config.productionTip = false
Vue.use(VueMaterial)
Vue.use(HelpersPlugin)
Vue.use(ApiPlugin);

DatePicker.props.format.default = "DD/MM/YYYY";
Vue.use(DatePicker);
Vue.use(ServicePlugin, router);

new Vue({
	router,
	i18n,
	store,
	render: h => h(App)
}).$mount('#app')
