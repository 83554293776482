<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    init() {
      // init i18n materials
      const materialPreferences = this.$t('materials');
      this.$material.locale = materialPreferences.locale;
      this.$material.ripple = materialPreferences.ripple;
      this.$material.theming = materialPreferences.theming;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/main";
</style>
