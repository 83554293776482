<script>
import moment from "moment";
import {mapActions, mapState} from "vuex";

export default {
  name: "Statistics",
  data() {
    return {
      loading: true,
      usersActivity: [],
      documentsActivity: [],
      showFilters: false,
      filters: {
        from: null,
        to: null,
        grouping: 'none',
      },
      disabledDatesForTo: date => {
        if (this.filters.from) {
          return moment(date).isSameOrBefore(this.filters.from);
        }

        return false;
      },
    };
  },
  computed: {
    ...mapState([
      'statisticsFilters',
    ]),
  },
  async mounted() {
    this.filters = this.statisticsFilters;
    this.showFilters = Object.values(this.filters).some(filter => filter !== null);

    await this.fetchData();

    this.loading = false;
  },
  methods: {
    ...mapActions([
      'setStatisticsFilters',
    ]),
    async fetchData() {
      const activity = await this.service.activity.summary(this.filters);
      this.usersActivity = activity.users;
      this.documentsActivity = activity.documents;
    },
    async applyFilters() {
      this.loading = true;

      if (this.filters.grouping === 'none') {
        this.filters.grouping = null;
      }

      this.setStatisticsFilters(this.filters);

      await this.fetchData();

      this.loading = false;
    },
    downloadReport() {
      this.service.activity.downloadReport('complete', this.filters);
    },
  },
};
</script>

<template>
  <div class="md-layout column">
    <md-toolbar md-elevation="0" class="md-transparent">
      <h1 class="md-title">{{ $t('statisticsPage.title') }}</h1>

      <div>
        <md-button class="md-icon-button" @click="showFilters = !showFilters">
          <md-icon>filter_list</md-icon>
          <md-tooltip md-direction="bottom">Filtra</md-tooltip>
        </md-button>

        <md-button class="md-icon-button" @click="downloadReport">
          <md-icon>download</md-icon>
          <md-tooltip md-direction="bottom">Scarica il report completo</md-tooltip>
        </md-button>
      </div>
    </md-toolbar>

    <div class="filters" v-if="showFilters">
      <md-datepicker id="filter-from" v-model="filters.from" md-immediately :md-open-on-focus="false">
        <label>Da</label>
      </md-datepicker>

      <md-datepicker id="filter-to" v-model="filters.to" :md-disabled-dates="disabledDatesForTo" md-immediately
                     :md-open-on-focus="false">
        <label>A</label>
      </md-datepicker>

      <md-field>
        <label for="grouping">Conteggio</label>
        <md-select v-model="filters.grouping" name="grouping" id="grouping">
          <md-option value="none">Somma</md-option>
          <md-option value="daily">Media giornaliera</md-option>
          <md-option value="monthly">Media mensile</md-option>
          <md-option value="yearly">Media annuale</md-option>
        </md-select>
      </md-field>

      <md-button class="md-raised md-primary apply-filters-btn" @click="applyFilters">Applica</md-button>
    </div>

    <div class="md-layout-item md-size-100" v-if="loading">
      <md-progress-bar md-mode="indeterminate"/>
    </div>

    <div class="md-layout row">
      <md-table v-if="!loading" v-model="usersActivity" md-card>
        <md-table-toolbar>
          <h2 class="md-title">{{ $t('statisticsPage.usersTableName') }}</h2>

          <router-link to="/statistics/activity/users">
            <md-button class="md-icon-button">
              <md-icon>open_in_new</md-icon>
              <md-tooltip md-direction="bottom">Vai ad attivit&agrave; utenti</md-tooltip>
            </md-button>
          </router-link>
        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item }"
                      @click="$router.push(`/statistics/activity/users?action=${item.action}`)">
          <md-table-cell md-label="Azione" md-sort-by="action">
            {{ $t(`trackingLabels.action.${item.action}`) }}
          </md-table-cell>
          <md-table-cell md-label="Conteggio" md-sort-by="count" md-numeric>{{ item.count }}</md-table-cell>
          <md-table-cell md-label="Fonti">
            {{
              `${item.sourcesPercentage.web}% ${$t('trackingLabels.source.web')} / ${item.sourcesPercentage.mobile}% ${$t('trackingLabels.source.mobile_app')}`
            }}
          </md-table-cell>
        </md-table-row>
      </md-table>

      <md-table v-if="!loading" v-model="documentsActivity" md-card>
        <md-table-toolbar>
          <h2 class="md-title">{{ $t('statisticsPage.documentsTableName') }}</h2>

          <router-link to="/statistics/activity/documents">
            <md-button class="md-icon-button">
              <md-icon>open_in_new</md-icon>
              <md-tooltip md-direction="bottom">Vai ad attivit&agrave; documenti</md-tooltip>
            </md-button>
          </router-link>
        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item }"
                      @click="$router.push(`/statistics/activity/documents?action=${item.action}`)">
          <md-table-cell md-label="Azione" md-sort-by="action">
            {{ $t(`trackingLabels.action.${item.action}`) }}
          </md-table-cell>
          <md-table-cell md-label="Conteggio" md-sort-by="count" md-numeric>{{ item.count }}</md-table-cell>
          <md-table-cell md-label="Fonti">
            {{
              `${item.sourcesPercentage.web}% ${$t('trackingLabels.source.web')} / ${item.sourcesPercentage.mobile}% ${$t('trackingLabels.source.mobile_app')}`
            }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<style scoped lang="scss">
.md-layout.column {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.md-layout.row {
  display: flex;
  flex-direction: row;
  gap: 2em;
  width: 100%;
  justify-content: stretch;
}

.md-table {
  flex-grow: 1;
  width: 100%;
}

.md-toolbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0 2rem;
}

.filters > * {
  width: 12rem;
}

.apply-filters-btn {
  width: 1.5rem;
}

.md-table-row {
  cursor: pointer;
}
</style>
