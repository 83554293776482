<template>
	<div class="md-layout">

		<md-toolbar md-elevation="0" class="md-transparent">
			<h1 class="md-title" style="flex: 1">{{ $t('documents.title') }}</h1>

			<div v-if="!loading">
				<md-button :disabled="rebuild_cache_loading" class="md-primary" @click="rebuildCache()">
					{{ rebuild_cache_text }}
				</md-button>
			</div>

		</md-toolbar>

		<div class="md-layout-item md-size-100" v-if="loading">
			<md-progress-bar md-mode="determinate" :md-value="loadingValue"></md-progress-bar>
			<p>
				{{ loadingValue }}%
				<span v-if="loadingValue < 10">Inizializzazione</span>
				<span v-if="loadingValue >= 10 && loadingValue < 20">Scaricamento delle cartelle e dei files</span>
				<span v-if="loadingValue >= 20 && loadingValue < 30">Creazione dell'albero dei files</span>
				<span v-if="loadingValue >= 30 && loadingValue < 40">Scaricamento dei permessi</span>
				<span v-if="loadingValue >= 40 && loadingValue < 50">Analisi e attribuzione dei permessi file</span>
				<span v-if="loadingValue >= 50 && loadingValue < 60">Creazione interfaccia files</span>
				<span v-if="loadingValue >= 60">Finalizzazione del sistema</span>
			</p>
		</div>

		<!-- filters -->
		<div class="md-layout-item md-size-100" v-if="!loading">
			<md-card class="md-layout-item md-size-100">
				<md-card-content>
					<TreeDocuments :documents="data || []" :roles="roles" :langs="langs"></TreeDocuments>
				</md-card-content>
			</md-card>
		</div>

		<div class="md-layout-item md-size-100" v-if="!loading">
			<md-card class="md-layout-item md-size-100">
				<md-card-content>
					<span v-if="data.length <= 0">{{ $t('common.no_items') }}</span>
				</md-card-content>
			</md-card>
		</div>

		<md-dialog :md-active.sync="dialogPreference.show" :md-click-outside-to-close="false" :md-close-on-esc="false">
			<md-dialog-title>{{ dialogPreference.title }}</md-dialog-title>

			<md-dialog-content>
				<md-progress-bar md-mode="determinate" v-if="dialogPreference.loading"
								 :md-value="dialogPreference.loadingValue"></md-progress-bar>
				<p v-if="dialogPreference.loading">
					{{ dialogPreference.loadingValue }}%<br><br>
					Applico {{ dialogPreference.loadingNumFilesElaborated}} permessi su un totale di {{dialogPreference.loadingNumFiles}}.<br>
					Tempo rimanente {{ dialogPreference.loadingTimerLabel }}
				</p>
				<table class="dialog_table"
					v-if="!dialogPreference.loading && dialogPreference.permissions && Object.keys(dialogPreference.permissions).length">
					<tr>
						<th class="head_tbl"></th>
						<th class="head_tbl" v-for="r in roles" v-bind:key="r.id">{{ r.label }}</th>
					</tr>
					<tr v-for="l in langs" v-bind:key="l" style="line-height: 60px;">
						<th>{{ l }}</th>
						<td v-for="r in roles" v-bind:key="r.id">
							<input class="permission_checkbox" type="checkbox" :id="`${r.id}__${l}`"
								   @change="updateChecked"/>
						</td>
					</tr>
				</table>
			</md-dialog-content>

			<md-dialog-actions v-if="!dialogPreference.loading">
				<md-button class="md-primary" @click="dialogPreference.show = false">Chiudi</md-button>
				<md-button class="md-primary" @click="savePermission()">Salva permessi</md-button>
			</md-dialog-actions>
		</md-dialog>
	</div>
</template>

<script>

import TreeDocuments from "../../components/TreeDocuments";
import {EventBus} from '../../events-bus';

const moment = require('moment');

export default {
	name: 'Documents',
	components: {TreeDocuments},
	data () {
		return {
			loading: true,
			data: [],
			dialogPreference: {
				loading: false,
				loadingValue: 0,
				loadingNumFiles: 0,
				loadingNumFilesElaborated: 0,
				loadingTimer: 0,
				loadingTimerLabel: "",
				show: false,
				title: "",
				permissions: {},
				item: null
			},
			roles: [],
			langs: ['it', 'en'],
			loadingValue: 0,
			rebuild_cache_loading: false,
			rebuild_cache_text: this.$t('common.rebuild_cache'),
			rebuild_cache_total: 0,
			rebuild_cache_index: 0
		}
	},
	methods: {
		recreateCache(role, lang) {
			return new Promise(resolve => {
				this.service.documents.recreateCache(role, lang)
					.then(() => {
						console.log(`Elaborazione ${role}, ${lang}`);
					})
				.finally(() => {
					setTimeout(() => {
						resolve();
					}, 50)
				})
			})
		},
		rebuildCache(index, data) {
			data = data || [];
			if(data.length) {
				this.rebuild_cache_loading = true;
				this.rebuild_cache_total = data.length;
				if(index < data.length) {
					this.rebuild_cache_text = `${index + 1}/${data.length}: ${data[index].role.label} (${data[index].lang})`;
					this.$forceUpdate();
					this.recreateCache(data[index].role.role, data[index].lang)
						.then(() => {
							index = index + 1;
							this.rebuildCache(index, data)
						})
				} else {
					console.log(`Elaborazione conclusa`);
					this.rebuild_cache_loading = false;
					this.rebuild_cache_text = this.$t('common.rebuild_cache');
				}
			} else {
				data = [];
				this.roles.forEach(r => {
					this.langs.forEach(l => {
						data.push({ role: r, lang: l });
					})
				});
				this.rebuildCache(0, data);
			}

		},
		updateLoading () {
			if (this.loading) {
				if (this.loadingValue < 100) {
					this.loadingValue += Math.floor(Math.random() * 2);
					if (this.loadingValue > 100) {
						this.loadingValue = 100;
					}
				}
				setTimeout(() => {
					this.updateLoading()
				}, 200)
			}
		},
		getChildren (father, result) {
			father.forEach(f => {
				result.push(f.id);
				this.getChildren(f.children, result);
			})
		},
		updatePermessi (data, childrenIds, permissionsForObject) {
			data.forEach(d => {
				if (d.id == this.dialogPreference.item.id || childrenIds.includes(d.id)) {
					d.permissions = permissionsForObject;
				}
				this.updatePermessi(d.children, childrenIds, permissionsForObject)
			})
		},
		savePermissionChunk (permissions, index) {
			index = index || 0;
			console.log(`Chunk ${index} di ${permissions.length}: ${permissions[index].length}`)
			this.dialogPreference.loadingNumFilesElaborated = this.dialogPreference.loadingNumFilesElaborated + permissions[index].length;
			this.dialogPreference.loadingValue = parseInt(((index / permissions.length) * 100).toFixed(0));
			this.$forceUpdate();

			this.service.documents.permissions(permissions[index], this.dialogPreference.item.id)
				.then((result) => {
					EventBus.$emit('success');
				})
				.catch(() => {
					EventBus.$emit('error');
				})
				.finally(() => {
					if (index < permissions.length - 1) {
						this.savePermissionChunk(permissions, index + 1);
					} else {
						this.dialogPreference.show = false;
						this.dialogPreference.loading = false;
					}
				})
		},
		savePermission () {
			this.dialogPreference.loadingValue = 0;
			this.dialogPreference.loadingNumFiles = 0;
			this.dialogPreference.loadingNumFilesElaborated = 0;
			let permissions = [];
			let permissionsForObject = [];
			let childrenIds = [];
			this.getChildren(this.dialogPreference.item.children, childrenIds);

			Object.keys(this.dialogPreference.permissions).forEach(k => {

				if (this.dialogPreference.permissions[k]) {
					const data = k.split("__");
					permissionsForObject.push({
						role_id: data[0],
						lang: data[1]
					});
					permissions.push({
						id: this.dialogPreference.item.id,
						role_id: data[0],
						lang: data[1]
					});
					childrenIds.forEach(id => {
						permissions.push({
							id: id,
							role_id: data[0],
							lang: data[1]
						});
					})
				}
			})

			this.dialogPreference.loadingNumFiles = permissions.length;

			// chunk array
			const perChunk = 500;
			const permissionsChunk = permissions.reduce((permissions, item, index) => {
				const chunkIndex = Math.floor(index / perChunk)
				if (!permissions[chunkIndex]) {
					permissions[chunkIndex] = [] // start a new chunk
				}
				permissions[chunkIndex].push(item)
				return permissions
			}, [])

			this.dialogPreference.loading = true;

			this.dialogPreference.loadingTimer = new Date().getTime();
			this.dialogPreference.loadingTimerLabel = "";
			//this.dialogPreference.loadingTimer = this.dialogPreference.loadingTimer.setSeconds(this.dialogPreference.loadingTimer.getSeconds() + 10 + (permissions.length * 5));
			this.dialogPreference.loadingTimer = this.dialogPreference.loadingTimer + ((3 + (permissionsChunk.length * 4)) * 1000)

			let that = this;
			const timeInterval = setInterval(function() {
				const now = new Date().getTime();
				const distance = that.dialogPreference.loadingTimer - now;
				console.log(that.dialogPreference.loadingTimer, now, new Date(), distance, (permissions.length * 5))
				var hours = `${Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))}`.padStart(2, "0");
				var minutes = `${Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))}`.padStart(2, "0");
				var seconds = `${Math.floor((distance % (1000 * 60)) / 1000)}`.padStart(2, "0");
				that.dialogPreference.loadingTimerLabel = `${minutes} minuti ${seconds} secondi`

				if (distance < 0 || that.dialogPreference.loading == false) {
					clearInterval(timeInterval);
					that.dialogPreference.loadingTimer = 0;
				}
				that.$forceUpdate();
			}, 1000);

			this.service.documents.permissionsClean(permissions, this.dialogPreference.item.id)
				.then((result) => {
					console.log(`Permissions`, permissions.length, `Chunks`, permissionsChunk.length);
					this.savePermissionChunk(permissionsChunk);

					setTimeout(() => {
						this.updatePermessi(this.data, childrenIds, permissionsForObject);
						this.$forceUpdate();
					}, 10);
				})
				.catch(() => {
					EventBus.$emit('error');
				})
		},
		updateChecked (e) {
			const key = e.target.id;
			const checked = e.target.checked;
			this.dialogPreference.permissions[key] = checked;
			this.$forceUpdate();
		},
		refresh () {
			this.loading = true;
			this.loadingValue = 0;
			this.service.roles.list(1, "label")
				.then(resultRoles => {
					this.roles = resultRoles.data;
					this.service.documents.list()
						.then(result => {
							this.data = result.data;
							this.loading = false;

							EventBus.$on('openPermission', (item) => {
								if (this.dialogPreference) {
									this.dialogPreference.title = `Permessi ${item.folder ? 'cartella' : 'file'}: ${item.name}`;

									this.dialogPreference.permissions = {};
									this.roles.forEach(r => {
										this.langs.forEach(l => {
											const key = `${r.id}__${l}`;
											const permissionsExist = (item.permissions || []).filter(p => {
												return p.role_id == r.id && p.lang == l;
											})
											let checked = false;
											if (permissionsExist && permissionsExist.length) {
												checked = true;
											}
											this.dialogPreference.permissions[key] = checked;
										})
									})
									this.dialogPreference.item = item;
									this.dialogPreference.show = true;

									setTimeout(() => {
										Object.keys(this.dialogPreference.permissions).forEach(k => {
											if (this.dialogPreference.permissions[k]) {
												document.getElementById(`${k}`).checked = true;
											}
										})
									}, 200)
								}
							})
						})
				})
			this.updateLoading();


		}
	},
	mounted () {
		this.refresh();
	}
}
</script>

<style lang="scss">
.name_path {
	color: gray;
	font-size: 10px;
}

.md-dialog-container {
	max-width: 100% !important;
}

.permission_checkbox {
	-ms-transform: scale(2); /* IE */
	-moz-transform: scale(2); /* FF */
	-webkit-transform: scale(2); /* Safari and Chrome */
	-o-transform: scale(2); /* Opera */
	transform: scale(2);
	padding: 10px;
	cursor: pointer;
}

.dialog_table th {
	background: #efefef;
}

.dialog_table td {
	text-align: center;
}

.head_tbl {
	width: 80px;
}
</style>
