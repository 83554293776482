import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        statisticsFilters: {
            from: null,
            to: null,
            grouping: 'none',
        },
    },
    mutations: {
        SET_STATISTICS_FILTERS(state, payload) {
            state.statisticsFilters = payload;
        },
    },
    actions: {
        setStatisticsFilters({commit}, filters) {
            commit('SET_STATISTICS_FILTERS', filters);
        },
    },
});
