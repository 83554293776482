import moment from "moment";

export default {
    install: function (Vue) {
        let service = {};
        Vue.mixin({
            mounted() {

                let helpers = {};

                /**
                 * Crea i parametri per la lista
                 * @param page
                 * @param sort
                 * @param search
                 * @param search_fields
                 * @returns {string}
                 */
                helpers.buildQueryParams = (page, sort) => {
                    page = page || 1;
                    sort = sort || "";
                    let objParams = {
                        page: page,
                        sort: sort,
                    };
                    let params = '';
                    for (var key in objParams) {
                        if (params != "") {
                            params += "&";
                        }
                        params += key + "=" + encodeURIComponent(objParams[key]);
                    }
                    return params;
                };
                service.helpers = helpers;

                /**
                 * Gestione del profilo
                 * @returns {Promise<unknown>}
                 */
                service.profile = () => {
                    let url = `/users/profile`;
                    return this.$api.get(url);
                };

                let users = {};
                users.list = (page, sort, items_per_page, filter) => {
                    items_per_page = items_per_page || 10;
                    let params = service.helpers.buildQueryParams(page, sort);
                    params = `${params}&items_per_page=${items_per_page}`;

                    // custom filters
                    let filters = [];
                    Object.keys(filter).map(f => {
                        let v = filter[f];
                        if (!(v == null || `${v}`.trim() == "")) {
                            if (f == "search") {
                                v = `%${filter[f]}%`;
                                f = `email,code,agency_code`;
                            }
                            if (f == "last") {
                                v = v.join("|");
                            }
                            filters.push(`filters[${f}]=${v}`);
                        }
                    });
                    params = `${params}&${filters.join('&')}`;

                    return this.$api.get(`/user?${params}`);
                };
                users.single = (id) => {
                    return this.$api.get(`/user/${id}`);
                };
                users.save = (item) => {
                    if (item.id && item.id !== "") {
                        return this.$api.put(`/user/${item.id}`, item);
                    }
                    return this.$api.post(`/user`, item);
                };
                users.import = (item) => {
                    return this.$api.post(`/user/import`, item);
                };
                users.delete = (id) => {
                    return this.$api.delete(`/user/${id}`);
                };
                users.resend = (email) => {
                    return this.$api.post(`/user/resend`, {
                        email: email,
                    });
                };
                service.users = users;


                let requests = {};
                requests.list = (page, sort, items_per_page, filter) => {
                    items_per_page = items_per_page || 10;
                    let params = service.helpers.buildQueryParams(page, sort);
                    params = `${params}&items_per_page=${items_per_page}`;

                    // custom filters
                    let filters = [];
                    Object.keys(filter).map(f => {
                        let v = filter[f];
                        if (!(v == null || `${v}`.trim() == "")) {
                            if (f == "search") {
                                v = `%${filter[f]}%`;
                                f = `first_name,last_name,email,company_name,company_area`;
                            }
                            if (f == "last") {
                                v = v.join("|");
                            }
                            filters.push(`filters[${f}]=${v}`);
                        }
                    });
                    params = `${params}&${filters.join('&')}`;

                    return this.$api.get(`/requests?${params}`);
                };
                service.requests = requests;

                let showroom = {};
                showroom.list = (page, sort, items_per_page, filter) => {
                    items_per_page = items_per_page || 10;
                    let params = service.helpers.buildQueryParams(page, sort);
                    params = `${params}&items_per_page=${items_per_page}`;

                    // custom filters
                    let filters = [];
                    Object.keys(filter).map(f => {
                        let v = filter[f];
                        if (!(v == null || `${v}`.trim() == "")) {
                            if (f == "search") {
                                v = `%${filter[f]}%`;
                                f = `first_name,last_name,email,company_name,company_area`;
                            }
                            if (f == "last") {
                                v = v.join("|");
                            }
                            filters.push(`filters[${f}]=${v}`);
                        }
                    });
                    params = `${params}&${filters.join('&')}`;

                    return this.$api.get(`/showroom?${params}`);
                };
                service.showroom = showroom;

                let roles = {};
                roles.list = (page, sort) => {
                    let params = service.helpers.buildQueryParams(page, sort);
                    return this.$api.get(`/role?${params}&items_per_page=100`);
                };
                service.roles = roles;


                let documents = {};
                /*documents.list = (page, sort, items_per_page, filter) => {
                    items_per_page = items_per_page || 10;
                    let params = service.helpers.buildQueryParams(page, sort);
                    params = `${params}&items_per_page=${items_per_page}`;

                    // custom filters
                    let filters = [];
                    Object.keys(filter).map(f => {
                        let v = filter[f];
                        if (!(v == null || `${v}`.trim() == "")) {
                            if (f == "search") {
                                v = `%${filter[f]}%`;
                                f = `name`
                            }
                            if (f == "last") {
                                v = v.join("|")
                            }
                            filters.push(`filters[${f}]=${v}`);
                        }
                    })
                    params = `${params}&${filters.join('&')}`;
                    return this.$api.get(`/admin/documents?${params}`);
                }*/
                documents.list = () => {
                    return this.$api.get(`/admin/documents`);
                };
                documents.permissions = (permissions, document_id) => {
                    return this.$api.post(`/admin/permissions/${document_id}`, {permissions: permissions});
                };
                documents.permissionsClean = (permissions, document_id) => {
                    return this.$api.post(`/admin/permissionsclean/${document_id}`, {permissions: permissions});
                };
                documents.recreateCache = (role, lang) => {
                    return this.$api.post(`/admin/rebuild-cache/${role}/${lang}`, {});
                };
                documents.showFilePermissions = (file_id) => {
                    return this.$api.get(`/admin/documents/permissions/${file_id}`, {});
                };
                service.documents = documents;

                let statistics = {};
                let topten = {};

                topten.documents = (from, to) => {
                    from = from || 0;
                    to = to || 0;
                    return this.$api.get(`/admin/topten_documents?from=${from}&to=${to}`);
                };
                topten.access = (from, to) => {
                    from = from || 0;
                    to = to || 0;
                    return this.$api.get(`/admin/topten_access?from=${from}&to=${to}`);
                };
                topten.downloads_by_country = (from, to) => {
                    from = from || 0;
                    to = to || 0;
                    return this.$api.get(`/admin/topten_downloads_by_country?from=${from}&to=${to}`);
                };
                topten.downloads_by_role = (from, to) => {
                    from = from || 0;
                    to = to || 0;
                    return this.$api.get(`/admin/topten_downloads_by_role?from=${from}&to=${to}`);
                };

                let counters = {};
                counters.logged_users = () => {
                    return this.$api.get(`/admin/logged_users`);
                };
                counters.signin_users = () => {
                    return this.$api.get(`/admin/signin_users`);
                };
                counters.total_downloads = () => {
                    return this.$api.get(`/admin/total_downloads`);
                };

                let graph = {};
                graph.last_documents = () => {
                    return this.$api.get(`/admin/last_documents`);
                };
                graph.logged_users_last_year_by_month = () => {
                    return this.$api.get(`/admin/logged_users_last_year_by_month`);
                };

                statistics.downloadExcel = (from, to) => {
                    from = from || 0;
                    to = to || 0;
                    return this.$api.get(`/admin/downloads_excel?from=${from}&to=${to}`);
                };

                statistics.topten = topten;
                statistics.counters = counters;
                statistics.graph = graph;
                service.statistics = statistics;

                service.activity = {};
                service.activity.summary = ({from, to, grouping}) => {
                    const params = new URLSearchParams();

                    if (from) {
                        params.append('from', moment(from).format('YYYY-MM-DD'));
                    }
                    if (to) {
                        params.append('to', moment(to).format('YYYY-MM-DD'));
                    }
                    if (grouping) {
                        params.append('grouping', grouping);
                    }

                    return this.$api.get('/admin/activity/summary', {}, params);
                };
                service.activity.users = (page, perPage, filters = {}) => {
                    const params = new URLSearchParams();

                    params.append('page', page);
                    params.append('per_page', perPage);

                    if (filters.from) {
                        params.append('from', moment(filters.from).format('YYYY-MM-DD'));
                    }
                    if (filters.to) {
                        params.append('to', moment(filters.to).format('YYYY-MM-DD'));
                    }
                    if (filters.actions && filters.actions.length > 0) {
                        params.append('actions', filters.actions.join());
                    }
                    if (filters.sources && filters.sources.length > 0) {
                        params.append('sources', filters.sources.join());
                    }
                    if (filters.email) {
                        params.append('email', filters.email);
                    }

                    return this.$api.get('/admin/activity/users', {}, params);
                };
                service.activity.documents = (page, perPage, filters = {}) => {
                    const params = new URLSearchParams();

                    params.append('page', page);
                    params.append('per_page', perPage);

                    if (filters.from) {
                        params.append('from', moment(filters.from).format('YYYY-MM-DD'));
                    }
                    if (filters.to) {
                        params.append('to', moment(filters.to).format('YYYY-MM-DD'));
                    }
                    if (filters.actions && filters.actions.length > 0) {
                        params.append('actions', filters.actions.join());
                    }
                    if (filters.sources && filters.sources.length > 0) {
                        params.append('sources', filters.sources.join());
                    }
                    if (filters.email) {
                        params.append('email', filters.email);
                    }

                    return this.$api.get('/admin/activity/documents', {}, params);
                };
                service.activity.downloadReport = async (type = 'complete', filters = {}) => {
                    let endpoint = null;
                    const params = new URLSearchParams();

                    let fileName;

                    switch (type) {
                        case  'complete':
                            endpoint = '/admin/activity/download-report/xlsx';
                            fileName = 'Statistiche';

                            if (filters.from) {
                                params.append('from', moment(filters.from).format('YYYY-MM-DD'));
                            }
                            if (filters.to) {
                                params.append('to', moment(filters.to).format('YYYY-MM-DD'));
                            }
                            if (filters.grouping) {
                                params.append('grouping', filters.grouping);
                            }
                            break;

                        case 'users':
                            endpoint = '/admin/activity/download-report/users/xlsx';
                            fileName = 'Attività utenti';

                            if (filters.from) {
                                params.append('from', moment(filters.from).format('YYYY-MM-DD'));
                            }
                            if (filters.to) {
                                params.append('to', moment(filters.to).format('YYYY-MM-DD'));
                            }
                            if (filters.actions && filters.actions.length > 0) {
                                params.append('actions', filters.actions.join());
                            }
                            if (filters.sources && filters.sources.length > 0) {
                                params.append('sources', filters.sources.join());
                            }
                            break;

                        case 'documents':
                            endpoint = '/admin/activity/download-report/documents/xlsx';
                            fileName = 'Attività sui documenti';

                            if (filters.from) {
                                params.append('from', moment(filters.from).format('YYYY-MM-DD'));
                            }
                            if (filters.to) {
                                params.append('to', moment(filters.to).format('YYYY-MM-DD'));
                            }
                            if (filters.actions && filters.actions.length > 0) {
                                params.append('actions', filters.actions.join());
                            }
                            if (filters.sources && filters.sources.length > 0) {
                                params.append('sources', filters.sources.join());
                            }
                            break;

                        default:
                            return;
                    }

                    const file = await this.$api.post(endpoint, {}, {
                        responseType: 'blob',
                        params,
                    });

                    const url = window.URL.createObjectURL(new Blob([file]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${fileName}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                };

                let editorial = {};
                editorial.get = () => {
                    return this.$api.get(`/admin/editorial-content`);
                };
                editorial.save = (item) => {
                    return this.$api.post(`/admin/editorial-content`, item);
                };
                service.editorial = editorial;
            },
        });
        Vue.prototype.service = service;
    },
};
