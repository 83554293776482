<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-toolbar md-elevation="0" class="md-transparent">
				<div style="flex:1">
					<md-button @click="goBase()">
						{{ $t('common.back') }}
					</md-button>
				</div>
				<div>
					<md-button v-if="$route.params.id > 0" class="md-accent">
						{{ $t('common.delete') }}
					</md-button>
				</div>
			</md-toolbar>
		</div>

		<!-- form -->
		<div class="md-layout-item">
			<md-card v-if="loading">
				<md-card-content>
					<md-progress-bar md-mode="indeterminate"></md-progress-bar>
				</md-card-content>
			</md-card>

			<md-card class="md-layout-item md-size-100" v-if="!loading">
				<md-card-header>
					<div class="md-title">
						<span v-if="$route.params.id <= 0">{{ $t('users.new') }}</span>
					</div>
				</md-card-header>
				<md-card-content>
					<form novalidate @submit.prevent="submitForm" v-if="form">
						<!-- Errors -->
						<div class="md-layout-item box-errors" v-if="error">
							<span>
								{{ $t(`users.errors.${error}`) }}
							</span>
						</div>

						<div class="md-layout md-gutter" v-for="f in form" v-bind:key="f.lang">
							<div class="md-layout-item md-size-100 md-small-size-100">
								<md-card class="md-layout-item md-size-100" v-if="!loading">
									<md-card-header>
										<div class="md-title">
											<span>{{ f.lang }}</span>
										</div>
									</md-card-header>
									<md-card-content>
										<md-field :class="getValidationClass(`label_image_${f.lang}`)">
											<div class="md-layout md-gutter">
												<!--<div class="md-layout-item md-size-50 md-small-size-100">
													<img :src="`${f.image}`" style="max-height: 200px">
												</div>-->
												<div class="md-layout-item md-size-50 md-small-size-100">
													<div :style="`width: 150px; height: 150px; background: url(${f.image}); background-size: contain; background-repeat: no-repeat;`">
													</div>
													<md-file :id="`image_${f.lang}`"
															 :name="`image_${f.lang}`"
															 autocomplete="company_name"
															 v-model="f.image"
															 @md-change="onFileUpload($event, f)"
															 style="cursor: pointer"
															 :disabled="loading"/>
												</div>
											</div>
										</md-field>
									</md-card-content>
								</md-card>
							</div>
						</div>

					</form>
				</md-card-content>
			</md-card>
		</div>

	</div>
</template>


<script>

import {validationMixin} from 'vuelidate'
// import {required, email, url} from 'vuelidate/lib/validators'
import {required} from 'vuelidate/lib/validators'
import {EventBus} from "@/events-bus";

export default {
	name: 'User',
	mixins: [validationMixin],
	data () {
		return {
			submitted: false,
			error: '',
			loading: false,
			form: [],
		};
	},
	validations: {
		form: {
			$each: {
				lang: {},
				title: {},
				subtitle: {},
				action: {},
				url: {},
				image: {required: true}
			}
		}
	},
	methods: {
		getValidationClass (fieldName, frm) {
			frm = frm || this.$v.form;
			if (this.$v) {
				const field = this.$helpers.getObjectByPath(frm, fieldName)
				if (field) {
					return {
						'md-invalid': field.$invalid && field.$dirty
					}
				}
			}
		},
		init () {
			this.loading = true;
			this.form = [];
			this.service.editorial.get()
				.then((res) => {
					res.map(r => {
						this.form.push(r);
					})
				})
				.finally(() => {
					this.loading = false
				})

		},
		onFileUpload(e, obj) {
			this.loading = true;
			if(e && e.item(0)) {
				let o = {...obj};
				o.title = `title`;
				o.subtitle = `subtitle`;
				o.action = `show`;
				o.image = ``;
				o.url = `http://localhost`;
				const reader = new FileReader();
				reader.readAsDataURL(e.item(0));

				reader.onload = () => {
					o.image = reader.result;
					this.service.editorial.save(o)
						.then((res) => {
							EventBus.$emit('success');
							this.init();
						})
						.catch(e => {
							if (e && e.messages) {
								EventBus.$emit('error', e.messages);
							}
							this.loading = false;
						})
				}
				reader.onerror = (error) => {
					EventBus.$emit('error', 'upload-error');
					this.loading = false;
				}
			}
		},
		submitForm (e) {
			console.log(e);
			/*this.$v.form.$touch();
			this.submitted = true;
			this.error = '';
			this.loading = true;

			let f = {...this.form};

			if (f.id === null) {
				delete f.id
			}

			f.enabled = (f.enabled == null) ? 0 : f.enabled;
			this.loading = true;
			this.service.users.save(f)
				.then(() => {
					EventBus.$emit('success');
					this.$router.push({name: 'users.page'});
				})
				.catch(e => {
					if (e && e.messages) {
						EventBus.$emit('error', e.messages);
					}
					this.loading = false;
				})*/
		}
	},
	mounted () {
		this.init();
	}
}
</script>

<style lang="scss">
</style>