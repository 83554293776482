<template>
	<div class="md-layout">

		<md-toolbar md-elevation="0" class="md-transparent">
			<h1 class="md-title" style="flex: 1">{{ $t('users.title') }}</h1>
			<md-button class="md-primary md-raised" :to="{ name: 'user.page', params: {id: 0} }">
				{{ $t('common.new') }}
			</md-button>
			<md-button class="md-primary md-raised" :to="{ name: 'user.import', params: {id: 0} }">
				{{ $t('common.import') }}
			</md-button>
		</md-toolbar>

		<div class="md-layout-item md-size-100" v-if="loading">
			<md-progress-bar md-mode="indeterminate"></md-progress-bar>
			<div>&nbsp;</div>
		</div>

		<!-- filters -->
		<div class="md-layout-item md-size-100" v-if="!loading">
			<md-toolbar class="md-transparent" md-elevation="0">
				<div class="md-toolbar-section-end">
					<form novalidate @submit.prevent="submitFilter">

						<div class="md-layout md-gutter">
							<div class="md-layout-item">
								<md-field class="md-focused">
									<label>{{ $t('users.filters.role') }}</label>
									<md-select v-model="formFilter.role">
										<md-option value="">{{ $t('common.all') }}</md-option>
										<md-option v-for="r in roles" :bind-key="r.id" :value="r.id">{{ r.label }}
										</md-option>
									</md-select>
								</md-field>
							</div>
							<!--<div class="md-layout-item">
								<md-field class="md-focused">
									<label>{{ $t('users.filters.last') }}</label>
									<date-picker value-type="YYYY-MM-DD" v-model="formFilter.last" range></date-picker>
								</md-field>
							</div>-->
							<div class="md-layout-item">
								<md-field class="md-focused">
									<label>{{ $t('users.filters.search') }}</label>
									<md-input v-model="formFilter.search"></md-input>
								</md-field>
							</div>
							<div class="md-layout-item">
								<md-field class="md-focused">
									<label>{{ $t('users.filters.enabled') }}</label>
									<md-select v-model="formFilter.enabled">
										<md-option value="">{{ $t('common.all') }}</md-option>
										<md-option :value="1">{{ $t('common.yes') }}</md-option>
										<md-option :value="0">{{ $t('common.no') }}</md-option>
									</md-select>
								</md-field>
							</div>
							<div class="md-layout-item">
								<md-button type="submit" class="md-accent md-raised" :disabled="loading">
									<span>
										<md-icon>search</md-icon>
									</span>
									<span v-if="loading">{{ $t("common.wait") }}</span>
								</md-button>
							</div>
							<div class="md-layout-item" v-if="isFiltered">
								<md-button type="button" @click="clearFilter()" class="md-accent md-raised"
										   :disabled="loading">
									<span>
										<md-icon>search_off</md-icon>
									</span>
								</md-button>
							</div>
						</div>
					</form>
				</div>
			</md-toolbar>
		</div>

		<div class="md-layout-item md-size-100" v-if="!loading">
			<md-card class="md-layout-item md-size-100">
				<md-card-content>
					<md-table v-model="data" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
							  @md-selected="onSelect" :md-sort-fn="onSort">

						<md-table-toolbar>
							<h1 class="md-title"></h1>
						</md-table-toolbar>

						<md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
							<div class="md-toolbar-section-start">
								{{ $t('users.table.delete.selection', {count: count}) }}
							</div>
							<div class="md-toolbar-section-end">
								<md-button class="md-icon-button" @click="dialogDeleteActive = true">
									<md-icon>delete</md-icon>
								</md-button>

								<md-dialog-confirm
									:md-active.sync="dialogDeleteActive"
									:md-title="$t('users.table.delete.title')"
									:md-content="$t('users.table.delete.description')"
									:md-confirm-text="$t('common.yes')"
									:md-cancel-text="$t('common.no')"
									@md-cancel="onDialogDeleteCancel"
									@md-confirm="onDialogDeleteConfirm"/>

							</div>
						</md-table-toolbar>


						<md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple" md-auto-select>
							<md-table-cell></md-table-cell>
							<md-table-cell :md-label="$t('users.table.code')" md-sort-by="code">
								{{ item.agency_code }}<br>
								<i><small>({{ item.code }})</small></i>
							</md-table-cell>
							<md-table-cell :md-label="$t('users.table.name')">
								<strong>{{ item.first_name }} {{ item.last_name }}</strong><br>
								{{ item.email }}<br>
								{{ $t('users.table.city') }}: {{ item.city }} <span v-if="item.country">({{
									item.country
								}})</span>
							</md-table-cell>
							<md-table-cell :md-label="$t('users.table.company')" md-sort-by="company_name">
								<strong>{{ item.company_name }}</strong><br>
								{{ item.company_area }}<br>
							</md-table-cell>
							<md-table-cell :md-label="$t('users.table.role')">
								{{
									roles.filter(f => {
										return f.role == item.role
									}).pop().label || ``
								}}
							</md-table-cell>
							<!--<md-table-cell :md-label="$t('users.table.download')" md-numeric md-sort-by="downloads">
								{{ item.downloads }}
							</md-table-cell>-->
							<md-table-cell :md-label="$t('users.table.created_at')" md-sort-by="created_at">
								{{ getDate(item.created_at) }}
							</md-table-cell>
							<md-table-cell :md-label="$t('users.table.updated_at')" md-sort-by="updated_at">
								{{ getDate(item.updated_at) }}
							</md-table-cell>
							<md-table-cell :md-label="$t('users.table.logged_at')" md-sort-by="logged_at">
								{{ getDate(item.logged_at) }}
							</md-table-cell>
							<md-table-cell :md-label="$t('users.table.last_access_at')" md-sort-by="last_access_at">
								{{ getDate(item.last_access_at) }}
							</md-table-cell>
							<md-table-cell :md-label="$t('users.table.last_password_changed_at')" md-sort-by="last_password_changed_at">
								{{ getDate(item.last_password_changed_at) }}
							</md-table-cell>
							<md-table-cell :md-label="$t('users.table.enabled')" md-sort-by="enabled">
								<md-switch @change="toggleEnabled(item)" v-model="item.enabled" :value="1"></md-switch>
							</md-table-cell>
							<md-table-cell>
								<md-button class="md-primary md-raised"
										   :to="{ name: 'user.page', params: {id: item.id} }">
									{{ $t('common.edit') }}
								</md-button>
							</md-table-cell>
							<md-table-cell>
								<md-button class="md-primary md-raised md-small"
										   @click="dialogResendObject = item; dialogResendActive = true">
									{{ $t('users.resend') }}
								</md-button>

								<md-dialog-confirm
									:md-active.sync="dialogResendActive"
									:md-title="$t('users.table.resend.title')"
									:md-content="`${$t('users.table.resend.description')} ${(dialogResendObject && dialogResendObject.email) ? dialogResendObject.email : '' }?`"
									:md-confirm-text="$t('common.yes')"
									:md-cancel-text="$t('common.no')"
									@md-cancel="onDialogResendCancel"
									@md-confirm="onDialogResendConfirm"/>

								<md-dialog-alert
									:md-active.sync="dialogResendSuccess"
									:md-title="$t('users.table.resend.title_success')"
									:md-content="$t('users.table.resend.description_success')"/>

							</md-table-cell>
						</md-table-row>
					</md-table>

					<span v-if="data.length <= 0">{{ $t('common.no_items') }}</span>
				</md-card-content>
			</md-card>
		</div>

		<div class="md-table-pagination" v-if="!loading">
			<template>
				<span class="md-table-pagination-label">{{ $t('users.pagination.rows_per_page') }}</span>
				<md-field>
					<md-select v-model="pagination.items_per_page" md-dense md-class="md-pagination-select">
						<md-option v-for="amount in [10, 15, 20, 50, 100, 200, 500]" :bind-key="amount" :value="amount">
							{{ amount }}
						</md-option>
					</md-select>
				</md-field>
			</template>

			<template>
				<span class="md-table-pagination-label">Pagina: </span>
				<md-field>
					<md-select v-model="selectedPage" md-dense md-class="md-pagination-select">
						<md-option v-for="p in pagination.pages" :bind-key="p" :value="p">
							{{ p }}
						</md-option>
					</md-select>
				</md-field>
			</template>

			<md-button class="md-icon-button md-table-pagination-previous" @click="changePage(pagination.page - 1)"
					   :disabled="pagination.page === 1">
				<md-icon>keyboard_arrow_left</md-icon>
			</md-button>

			<md-button class="md-icon-button md-table-pagination-next" @click="changePage(pagination.page + 1)"
					   :disabled="pagination.page >= pagination.pages">
				<md-icon>keyboard_arrow_right</md-icon>
			</md-button>
		</div>

	</div>
</template>

<script>


import {EventBus} from "@/events-bus";
import moment from "moment";

export default {
	name: 'Users',
	data () {
		return {
			loading: true,
			data: [],
			roles: [],
			selected: [],
			currentSort: 'code',
			currentSortOrder: 'asc',
			lastCurrentSort: 'code',
			lastCurrentSortOrder: 'asc',
			isSorting: false,
			selectedPage: 1,
			pagination: {
				page: 1,
				pages: null,
				total: null,
				items_per_page: 10
			},
			isFiltered: false,
			formFilter: {
				role: '',
				search: '',
				enabled: '',
				last: null
			},
			dialogDeleteActive: false,
			dialogResendActive: false,
			dialogResendObject: null,
			dialogResendSuccess: false,
		}
	},
	watch: {
		'pagination.items_per_page': {
			immediate: false,
			handler () {
				this.refresh(true);
			}
		},
		'selectedPage': {
			immediate: false,
			handler () {
				this.pagination.page = this.selectedPage;
				this.refresh(false);
			}
		}
	},
	methods: {
		/**
		 *
		 * @param reInitParams - true if page start at 1
		 */
		refresh (reInitParams) {
			reInitParams = reInitParams || false;
			if (reInitParams) {
				this.pagination.page = 1;
			}
			this.loading = true;
			this.service.roles.list(1, "label")
				.then(resultRoles => {
					this.roles = resultRoles.data;
					let s = this.currentSort;
					s = (this.currentSortOrder == 'asc') ? s : `-${s}`;
					this.lastCurrentSort = this.currentSort;
					this.lastCurrentSortOrder = this.currentSortOrder;
					this.service.users.list(this.pagination.page, s, this.pagination.items_per_page, this.formFilter)
						.then(result => {
							this.data = result.data;
							this.pagination.pages = result.pages;
							this.pagination.total = result.total;
							this.loading = false;
						})
				})

		},
		onSort(e) {
			if(this.currentSort !== this.lastCurrentSort || this.currentSortOrder !== this.lastCurrentSortOrder) {
				console.log(`Current: ${this.currentSort}, Order: ${this.currentSortOrder}`)
				this.refresh(true);
			}
		},
		onSelect (items) {
			this.selected = items
		},
		changePage (page) {
			this.pagination.page = page;
			this.refresh();
		},
		submitFilter () {
			this.isFiltered = true;
			this.refresh(true)
		},
		clearFilter () {
			this.isFiltered = false;
			this.formFilter.role = ''
			this.formFilter.search = ''
			this.formFilter.enabled = ''
			this.formFilter.last = null
			this.refresh(true)
		},
		toggleEnabled (item) {
			item.enabled = (item.enabled == null) ? 0 : item.enabled;

			this.service.users.save(item)
				.then(() => {
					EventBus.$emit('save-success');
				})
		},
		onDialogDeleteConfirm () {
			let ids = [];
			this.selected.map(s => {
				ids.push(s.id);
			})
			if (ids && ids.length) {
				this.service.users.delete(ids)
					.then(() => {
						EventBus.$emit('delete-success');
						this.refresh(true);
					})
			}
		},
		onDialogDeleteCancel () {
		},
		getDate (ts) {
			if (ts) {
				ts = ts.replace(".000000Z", "");
				ts = ts.replace("T", " ");
				return moment(ts).format("DD/MM/YYYY hh:mm")
			}
			return '';


		},
		onDialogResendConfirm () {
			this.loading = true;

			this.service.users.resend(this.dialogResendObject.email)
				.then(() => {
					let s = this.currentSort;
					s = (this.currentSortOrder == 'asc') ? s : `-${s}`;
					this.service.users.list(this.pagination.page, s, this.pagination.items_per_page, this.formFilter)
						.then(result => {
							this.data = result.data;
							this.pagination.pages = result.pages;
							this.pagination.total = result.total;
							this.dialogResendObject = null;
							this.dialogResendActive = false;
							this.dialogResendSuccess = true;
							this.loading = false;
						})
				})
				.finally(() => {

				})
		},
		onDialogResendCancel () {
			this.loading = true;
			let s = this.currentSort;
			s = (this.currentSortOrder == 'asc') ? s : `-${s}`;
			this.service.users.list(this.pagination.page, s, this.pagination.items_per_page, this.formFilter)
				.then(result => {
					this.data = result.data;
					this.pagination.pages = result.pages;
					this.pagination.total = result.total;
					this.dialogResendObject = null;
					this.dialogResendActive = false;
					this.loading = false;
				})
		},
	},
	mounted () {
		this.refresh();
	}
}
</script>

<style lang="scss">
</style>
