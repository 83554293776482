<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-toolbar md-elevation="0" class="md-transparent">
				<div style="flex:1">
					<md-button @click="goBase()">
						{{ $t('common.back') }}
					</md-button>
				</div>
			</md-toolbar>
		</div>

		<!-- form -->
		<div class="md-layout-item form_import">
			<md-card class="md-layout-item md-size-100">
				<md-card-header>
					<div class="md-title">
						<span>{{ $t('import.title') }}</span>
					</div>
				</md-card-header>

				<md-card-content v-if="loading">
					<md-progress-bar md-mode="indeterminate"></md-progress-bar>
				</md-card-content>

				<md-card-content v-if="!loading">
					<div class="md-layout md-gutter">
						<div class="md-layout-item">

							<label v-if="!data.length" for="file-upload" class="custom-file-upload">
								<i class="md-icon md-icon-font md-icon-image md-file-icon md-theme-default">
									<svg height="24" viewBox="0 0 24 24" width="24"
										 xmlns="http://www.w3.org/2000/svg">
										<path
											d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"></path>
										<path d="M0 0h24v24H0z" fill="none"></path>
									</svg>
								</i>
								{{ $t('import.input_placeholder') }}
							</label>
							<input v-if="!data.length" ref="doc" @change="readFile()" id="file-upload" type="file">

						</div>
					</div>
					<div class="md-layout md-gutter" v-if="error">
						<div class="md-layout-item file_error">
							{{ error }}
						</div>
					</div>
					<div class="md-layout md-gutter" v-if="success">
						<div class="md-layout-item file_success">
							{{ success }}
						</div>
					</div>
					<div class="md-layout md-gutter" v-if="error">
						<div class="md-layout-item file_error">
							<md-table v-model="errorData">
								<md-table-row slot="md-table-row" slot-scope="{ item }">
									<md-table-cell :md-label="$t('import.table.code')">
										{{$t('import.table.code')}}: {{ item.item.code }}<br>
										{{$t('import.table.email')}}: {{ item.item.email }}<br>
										{{$t('import.table.role')}}: {{ item.item.role }}<br>
										{{$t('import.table.name')}}: {{ item.item.first_name }} {{ item.item.last_name }}<br>
										{{$t('import.table.city')}}/{{$t('import.table.country')}}: {{ item.item.city }}/{{ item.item.country }}
									</md-table-cell>
									<md-table-cell>
										<div class="file_error_table" v-for="e in item.errors" v-bind:key="e">
											{{ $t(`import.errors.${e}`)}}
										</div>
									</md-table-cell>
								</md-table-row>
							</md-table>
						</div>
					</div>
				</md-card-content>

				<md-card-content v-if="data.length">
					<md-toolbar md-elevation="1" class="md-primary">
						<!--<div style="flex:1">
						</div>-->
						<div>
							<!--{{ $t('import.text_import') }}-->
							<md-button v-if="!loading" class="md-primary" style="color: greenyellow" @click="importData()">
								{{ $t('common.import') }}
							</md-button>

							<md-button v-if="!loading" class="md-primary" style="color: red" @click="data = [];">
								{{ $t('common.abort') }}
							</md-button>
							<p v-if="loading">
								{{ $t('import.import_loading') }}
							</p>
						</div>
					</md-toolbar>

					<md-table v-model="data">
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell :md-label="$t('import.table.code')" md-sort-by="code">
								{{ item.code }}
							</md-table-cell>
							<md-table-cell :md-label="$t('import.table.email')" md-sort-by="email">
								{{ item.email }}
							</md-table-cell>
							<md-table-cell :md-label="$t('import.table.role')" md-sort-by="role">
								{{ item.role }}
							</md-table-cell>
							<md-table-cell :md-label="$t('import.table.name')" md-sort-by="first_name">
								<strong>{{ item.first_name }} {{ item.last_name }}</strong>
							</md-table-cell>
							<md-table-cell :md-label="$t('import.table.company')" md-sort-by="email">
								{{ item.company_name }} <span v-if="item.company_area">({{ item.company_area }})</span>
							</md-table-cell>
							<md-table-cell :md-label="$t('import.table.city')" md-sort-by="city">
								{{ item.city }}
							</md-table-cell>
							<md-table-cell :md-label="$t('import.table.country')" md-sort-by="country">
								{{ item.country }}
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
			</md-card>
		</div>

	</div>
</template>


<script>

import {validationMixin} from 'vuelidate'

export default {
	name: 'UserImport',
	mixins: [validationMixin],
	data () {
		return {
			submitted: false,
			error: '',
			errorData: [],
			success: '',
			file: null,
			loading: false,
			data: [],
		};
	},
	methods: {
		getValidationClass (fieldName, frm) {
			frm = frm || this.$v.form;
			if (this.$v) {
				const field = this.$helpers.getObjectByPath(frm, fieldName)
				if (field) {
					return {
						'md-invalid': field.$invalid && field.$dirty
					}
				}
			}
		},
		init () {
		},
		goBase () {
			this.$router.push({name: 'users.page'});
		},
		importUser () {
			const item = this.data[0];
			this.service.users.import(item)
				.then(() => {
				})
				.catch((e) => {
					this.errorData.push({
						errors: Array.isArray(e.messages) ? e.messages : [e.messages],
						item: item
					})
				})
				.finally(() => {
					setTimeout(() => {
						this.data.shift();
						if (this.data.length) {
							this.$forceUpdate();
							this.importUser();
						} else {
							if(this.errorData.length) {
								this.loading = false;
								this.error = this.$t('import.text_import_error')
							} else {
								this.loading = false;
								this.success = this.$t('import.text_success')
							}

						}
					}, 10)
				})
		},
		importData () {
			this.loading = true;
			this.importUser();
		},
		readFile () {
			const file = this.$refs.doc.files[0];
			this.error = "";
			this.success = "";
			this.errorData = [];
			this.data = [];

			if (file.type == "text/plain" && file.name.toLowerCase().includes(".txt")) {
				this.loading = true;

				setTimeout(() => {
					const reader = new FileReader();
					reader.onload = (res) => {
						const data = res.target.result
						data.split("\n").forEach(item => {
							if (item.trim().length) {
								const i = item.split("|");
								const d = {
									code: i[0],
									email: i[1],
									role: i[2],
									first_name: i[3],
									last_name: i[4],
									company_name: i[5],
									company_area: i[6],
									city: i[7],
									country: i[8].replace(/(\r\n|\n|\r)/gm, ""),
								}
								this.data.push(d)
							}
						})
						this.loading = false;
					};
					reader.onerror = (err) => console.log(err);
					reader.readAsText(file);
				}, 100)

			} else {
				this.error = this.$t("import.errors.error_file")
				this.loading = false;
			}
		}
	},
	mounted () {
		this.init();
	}
}
</script>

<style lang="scss">
.form_import {
	input[type="file"] {
		display: none;
	}

	.custom-file-upload {
		border: 1px solid #ccc;
		display: inline-block;
		padding: 12px 24px;
		cursor: pointer;
	}

	.custom-file-upload {
		cursor: pointer;
	}

	.file_error {
		padding: 20px 0 0 20px;
		color: red;
	}
	.file_error_table {
		color: red;
	}
	.file_success {
		padding: 20px 0 0 20px;
		color: green;
	}
}

</style>
