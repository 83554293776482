<template>
	<div class="md-layout">

		<md-toolbar md-elevation="0" class="md-transparent">
			<h1 class="md-title" style="flex: 1">{{ $t('requests.title') }}</h1>
		</md-toolbar>

		<div class="md-layout-item md-size-100" v-if="loading">
			<md-progress-bar md-mode="indeterminate"></md-progress-bar>
			<div>&nbsp;</div>
		</div>

		<!-- filters -->
		<div class="md-layout-item md-size-100" v-if="!loading">
			<md-toolbar class="md-transparent" md-elevation="0">
				<div class="md-toolbar-section-end">
					<form novalidate @submit.prevent="submitFilter">

						<div class="md-layout md-gutter">
							<div class="md-layout-item">
								<md-field class="md-focused">
									<label>{{ $t('users.filters.search') }}</label>
									<md-input v-model="formFilter.search"></md-input>
								</md-field>
							</div>
							<div class="md-layout-item">
								<md-button type="submit" class="md-accent md-raised" :disabled="loading">
									<span>
										<md-icon>search</md-icon>
									</span>
									<span v-if="loading">{{ $t("common.wait") }}</span>
								</md-button>
							</div>
							<div class="md-layout-item" v-if="isFiltered">
								<md-button type="button" @click="clearFilter()" class="md-accent md-raised"
										   :disabled="loading">
									<span>
										<md-icon>search_off</md-icon>
									</span>
								</md-button>
							</div>
						</div>
					</form>
				</div>
			</md-toolbar>
		</div>

		<div class="md-layout-item md-size-100" v-if="!loading">
			<md-card class="md-layout-item md-size-100">
				<md-card-content>
					<md-table v-model="data" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
							  @md-selected="onSelect">

						<md-table-toolbar>
							<h1 class="md-title"></h1>
						</md-table-toolbar>

						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell :md-label="$t('requests.table.name')" md-sort-by="first_name">
								<strong>{{ item.first_name }} {{ item.last_name }}</strong>
							</md-table-cell>
							<md-table-cell :md-label="$t('requests.table.email')" md-sort-by="email">
								{{ item.email }}
							</md-table-cell>
							<md-table-cell :md-label="$t('requests.table.company_name')" md-sort-by="company_name">
								{{ item.company_name }}<br>
								<span v-if="item.company_area">{{ company_area }}</span>
							</md-table-cell>
							<md-table-cell :md-label="$t('requests.table.city')" md-sort-by="city">
								{{ item.city }}
							</md-table-cell>
							<md-table-cell :md-label="$t('requests.table.country')" md-sort-by="country">
								{{ item.country }}
							</md-table-cell>
							<md-table-cell :md-label="$t('requests.table.created_at')" md-sort-by="created_at">
								{{ getDate(item.created_at) }}
							</md-table-cell>
						</md-table-row>
					</md-table>

					<span v-if="data.length <= 0">{{ $t('common.no_items') }}</span>
				</md-card-content>
			</md-card>
		</div>

		<div class="md-table-pagination" v-if="!loading">
			<template>
				<span class="md-table-pagination-label">{{ $t('requests.pagination.rows_per_page') }}</span>
				<md-field>
					<md-select v-model="pagination.items_per_page" md-dense md-class="md-pagination-select">
						<md-option v-for="amount in [10, 15, 20, 50, 100, 200, 500]" :bind-key="amount" :value="amount">
							{{ amount }}
						</md-option>
					</md-select>
				</md-field>
			</template>

			<span>{{ $t('requests.pagination.title', {page: pagination.page, pages: pagination.pages}) }}</span>

			<md-button class="md-icon-button md-table-pagination-previous" @click="changePage(pagination.page - 1)"
					   :disabled="pagination.page === 1">
				<md-icon>keyboard_arrow_left</md-icon>
			</md-button>

			<md-button class="md-icon-button md-table-pagination-next" @click="changePage(pagination.page + 1)"
					   :disabled="pagination.page >= pagination.pages">
				<md-icon>keyboard_arrow_right</md-icon>
			</md-button>
		</div>

	</div>
</template>

<script>


import {EventBus} from "@/events-bus";
import moment from "moment";

export default {
	name: 'Requests',
	data () {
		return {
			loading: true,
			data: [],
			roles: [],
			selected: [],
			currentSort: 'created_at',
			currentSortOrder: 'desc',
			pagination: {
				page: 1,
				pages: null,
				total: null,
				items_per_page: 10
			},
			isFiltered: false,
			formFilter: {
				search: '',
			},
			dialogDeleteActive: false
		}
	},
	watch: {
		'pagination.items_per_page': {
			immediate: false,
			handler () {
				this.refresh(true);
			}
		}
	},
	methods: {
		/**
		 *
		 * @param reInitParams - true if page start at 1
		 */
		refresh (reInitParams) {
			reInitParams = reInitParams || false;
			if (reInitParams) {
				this.pagination.page = 1;
			}
			this.loading = true;
			this.service.roles.list(1, "label")
				.then(resultRoles => {
					this.roles = resultRoles.data;
					let s = this.currentSort;
					s = (this.currentSortOrder == 'asc') ? s : `-${s}`;
					this.service.requests.list(this.pagination.page, s, this.pagination.items_per_page, this.formFilter)
						.then(result => {
							this.data = result.data;
							this.pagination.pages = result.pages;
							this.pagination.total = result.total;
							this.loading = false;
						})
				})
		},
		onSelect (items) {
			this.selected = items
		},
		changePage (page) {
			this.pagination.page = page;
			this.refresh();
		},
		submitFilter () {
			this.isFiltered = true;
			this.refresh(true)
		},
		getDate(ts) {
			return moment(ts).format("DD/MM/YYYY")
		},
		clearFilter () {
			this.isFiltered = false;
			this.formFilter.search = ''
			this.refresh(true)
		}
	},
	mounted () {
		this.refresh();
	}
}
</script>

<style lang="scss">
</style>