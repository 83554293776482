<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-toolbar md-elevation="0" class="md-transparent">
				<div style="flex:1">
					<md-button @click="goBase()">
						{{ $t('common.back') }}
					</md-button>
				</div>
				<div>
					<md-button v-if="$route.params.id > 0" class="md-accent">
						{{ $t('common.delete') }}
					</md-button>
				</div>
			</md-toolbar>
		</div>

		<!-- form -->
		<div class="md-layout-item">
			<md-card v-if="loading">
				<md-card-content>
					<md-progress-bar md-mode="indeterminate"></md-progress-bar>
				</md-card-content>
			</md-card>

			<md-card class="md-layout-item md-size-100" v-if="!loading">
				<md-card-header>
					<div class="md-title">
						<span v-if="$route.params.id <= 0">{{ $t('users.new') }}</span>
					</div>
				</md-card-header>
				<md-card-content>
					<form novalidate @submit.prevent="submitForm" v-if="form">
						<!-- Errors -->
						<div class="md-layout-item box-errors" v-if="error">
							<span>
								{{ $t(`users.errors.${error}`) }}
							</span>
						</div>

						<!-- code -->
						<div class="md-layout md-gutter">
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('code')">
									<label for="code">
										{{ $t("users.form.code") }}
									</label>
									<md-input type="text"
											  id="code"
											  name="code"
											  autocomplete="code"
											  v-model="form.code"
											  :disabled="loading"/>
									<span class="md-error" v-if="!$v.form.code.required">
										{{ $t("users.errors.item-required") }}
									</span>
								</md-field>
							</div>
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('agency_code')">
									<label for="agency_code">
										{{ $t("users.form.agency_code") }}
									</label>
									<md-input type="text"
											  id="agency_code"
											  name="agency_code"
											  autocomplete="agency_code"
											  v-model="form.agency_code"
											  :disabled="loading"/>
									<!--<span class="md-error" v-if="!$v.form.agency_code.required">
										{{ $t("users.errors.item-required") }}
									</span>-->
								</md-field>
							</div>
						</div>

						<!-- name -->
						<div class="md-layout md-gutter">
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('first_name')">
									<label for="first_name">
										{{ $t("users.form.first_name") }}
									</label>
									<md-input type="text"
											  id="first_name"
											  name="first_name"
											  autocomplete="first_name"
											  v-model="form.first_name"
											  :disabled="loading"/>
									<!--<span class="md-error" v-if="!$v.form.first_name.required">
										{{ $t("users.errors.item-required") }}
									</span>-->
								</md-field>
							</div>
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('last_name')">
									<label for="last_name">
										{{ $t("users.form.last_name") }}
									</label>
									<md-input type="text"
											  id="last_name"
											  name="last_name"
											  autocomplete="last_name"
											  v-model="form.last_name"
											  :disabled="loading"/>
									<!--<span class="md-error" v-if="!$v.form.last_name.required">
										{{ $t("users.errors.item-required") }}
									</span>-->
								</md-field>
							</div>
						</div>

						<!-- email & role -->
						<div class="md-layout md-gutter">
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('email')">
									<label for="email">
										{{ $t("users.form.email") }}
									</label>
									<md-input type="text"
											  id="email"
											  name="email"
											  autocomplete="email"
											  v-model="form.email"
											  :disabled="loading"/>
									<span class="md-error" v-if="!$v.form.email.required">
									{{ $t("users.errors.item-required") }}
								</span>
									<span class="md-error" v-if="!$v.form.email.email">
									{{ $t("users.errors.email_not_valid") }}
								</span>
								</md-field>
							</div>
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('role')">
									<label>
										{{ $t("users.form.role") }}
									</label>
									<md-select v-model="form.role">
										<md-option v-for="r in roles" :bind-key="r.id" :value="r.id">{{ r.label }}
										</md-option>
									</md-select>
									<!--<span class="md-error" v-if="!$v.form.role.required">
										{{ $t("users.errors.item-required") }}
									</span>-->
								</md-field>
							</div>
						</div>

						<!-- password -->
						<!--<div class="md-layout md-gutter">
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('password')">
									<label for="code">
										{{ $t("users.form.password") }}
									</label>
									<md-input type="password"
											  id="password"
											  name="password"
											  autocomplete="password"
											  v-model="form.password"
											  :disabled="loading"/>
									<span class="md-helper-text">
										{{ $t("users.form.password_help") }}
									</span>
									<span class="md-error" v-if="!$v.form.password.required">
										{{ $t("users.errors.item-required") }}
									</span>
								</md-field>
							</div>
						</div>-->

						<!-- company -->
						<div class="md-layout md-gutter">
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('company_name')">
									<label for="company_name">
										{{ $t("users.form.company_name") }}
									</label>
									<md-input type="text"
											  id="company_name"
											  name="company_name"
											  autocomplete="company_name"
											  v-model="form.company_name"
											  :disabled="loading"/>
									<!--<span class="md-error" v-if="!$v.form.company_name.required">
										{{ $t("users.errors.item-required") }}
									</span>-->
								</md-field>
							</div>
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('company_area')">
									<label for="company_area">
										{{ $t("users.form.company_area") }}
									</label>
									<md-input type="text"
											  id="company_area"
											  name="company_area"
											  autocomplete="company_area"
											  v-model="form.company_area"
											  :disabled="loading"/>
									<!--<span class="md-error" v-if="!$v.form.company_area.required">
										{{ $t("users.errors.item-required") }}
									</span>-->
								</md-field>
							</div>
						</div>


						<!-- area -->
						<div class="md-layout md-gutter">
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('city')">
									<label for="city">
										{{ $t("users.form.city") }}
									</label>
									<md-input type="text"
											  id="city"
											  name="city"
											  autocomplete="city"
											  v-model="form.city"
											  :disabled="loading"/>
									<!--<span class="md-error" v-if="!$v.form.company_name.required">
										{{ $t("users.errors.item-required") }}
									</span>-->
								</md-field>
							</div>
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('country')">
									<label for="country">
										{{ $t("users.form.country") }}
									</label>
									<md-input type="text"
											  id="country"
											  name="country"
											  autocomplete="country"
											  v-model="form.country"
											  maxlength="2"
											  :disabled="loading"/>
									<!--<span class="md-error" v-if="!$v.form.company_area.required">
										{{ $t("users.errors.item-required") }}
									</span>-->
								</md-field>
							</div>
						</div>

						<!-- actions -->
						<md-card-actions>
							<md-button type="submit" class="md-primary" :disabled="loading">
								{{ $t("users.form.submit") }}
							</md-button>
						</md-card-actions>
					</form>
				</md-card-content>
			</md-card>
		</div>

	</div>
</template>


<script>

import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'
import {EventBus} from "@/events-bus";

export default {
	name: 'User',
	mixins: [validationMixin],
	data () {
		return {
			submitted: false,
			error: '',
			loading: false,
			roles: [],
			form: {
				id: null,
				code: null,
				agency_code: null,
				first_name: null,
				last_name: null,
				email: null,
				password: null,
				company_name: null,
				company_area: null,
				role: null,
				address: null,
				house_number: null,
				postal_code: null,
				city: null,
				county: null,
				country: null,
				phone: null,
				enabled: null
			}
		};
	},
	validations: {
		form: {
			id: {},
			agency_code: {},
			code: {required: true},
			first_name: {},
			last_name: {},
			email: {required: true, email},
			password: {},
			company_name: {},
			company_area: {},
			role: {required: true},
			address: {},
			house_number: {},
			postal_code: {},
			city: {},
			county: {},
			country: {},
			phone: {},
			enabled: {}
		}
	},
	methods: {
		getValidationClass (fieldName, frm) {
			frm = frm || this.$v.form;
			if (this.$v) {
				const field = this.$helpers.getObjectByPath(frm, fieldName)
				if (field) {
					return {
						'md-invalid': field.$invalid && field.$dirty
					}
				}
			}
		},
		init () {
			this.loading = true;
			this.service.roles.list(1, "label")
				.then(resultRoles => {
					this.roles = resultRoles.data;
					if (this.$route.params.id) {
						this.service.users.single(this.$route.params.id)
							.then(data => {
								Object.keys(this.form).forEach(k => {
									if (k === "role") {
										const role = this.roles.filter(r => {
											return r.role == data[k]
										});
										if (role && role[0]) {
											this.form[k] = role[0].id
										}
									} else {
										this.form[k] = data[k];
									}
								})
							})
							.finally(() => {
								this.loading = false;
							})
					} else {
						this.form.role = this.roles[0].id
						this.loading = false;
					}
				});
		},
		goBase () {
			this.$router.push({name: 'users.page'});
		},
		submitForm () {
			this.$v.form.$touch();
			this.submitted = true;
			this.error = '';
			this.loading = true;

			let f = {...this.form};

			if (f.id === null) {
				delete f.id
			}

			f.country = f.country.toUpperCase();
			f.enabled = (f.enabled == null) ? 0 : f.enabled;
			this.loading = true;
			this.service.users.save(f)
				.then(() => {
					EventBus.$emit('success');
					this.$router.push({name: 'users.page'});
				})
				.catch(e => {
					if(e && e.messages) {
						EventBus.$emit('error', e.messages);
					}
					this.loading = false;
				})
		}
	},
	mounted () {
		this.init();
	}
}
</script>

<style lang="scss">
</style>
