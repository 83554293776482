<script>
import moment from "moment/moment";
import {mapState} from "vuex";

export default {
  name: "DocumentsActivity",
  props: {
    action: String,
  },
  data() {
    return {
      documentsActivity: {
        mdCount: null,
        mdPage: null,
        mdData: [],
      },
      pagination: {
        rowsPerPage: 10,
      },
      currentSort: 'performedAt',
      currentSortOrder: 'asc',
      loading: true,
      showFilters: false,
      filters: {
        from: null,
        to: null,
        actions: [],
        sources: [],
        email: null,
      },
      disabledDatesForTo: date => {
        if (this.filters.from) {
          return moment(date).isSameOrBefore(this.filters.from);
        }

        return false;
      },
    };
  },
  computed: {
    ...mapState([
      'statisticsFilters',
    ]),
  },
  async created() {
    this.filters.from = this.statisticsFilters.from;
    this.filters.to = this.statisticsFilters.to;

    this.showFilters = Object.values(this.filters).some(filter => {
      if (Array.isArray(filter)) {
        return filter.length > 0;
      }
      return filter !== null;
    });

    if (this.action) {
      this.filters.actions.push(this.action);
    }

    await this.updatePagination(1, this.pagination.rowsPerPage);

    this.loading = false;
  },
  methods: {
    async updatePagination(page, pageSize) {
      const response = await this.service.activity.documents(page, pageSize, this.filters);

      this.documentsActivity.mdData = response.data.map((activity) => {
        const performedAtDate = new Date(activity.performedAt);
        const performedAtLabel = moment(activity.performedAt).format('DD/MM/YYYY HH:mm');

        return {
          ...activity,
          performedAt: {
            date: performedAtDate,
            label: performedAtLabel,
          },
        };
      });

      this.documentsActivity.mdPage = response.meta.current_page;
      this.documentsActivity.mdCount = response.meta.total;
      this.pagination.rowsPerPage = response.meta.per_page;
    },
    sortTable(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;

        if (sortBy === 'performedAt') {
          if (this.currentSortOrder === 'desc') {
            return a[sortBy].date.getTime() - b[sortBy].date.getTime();
          } else {
            return b[sortBy].date.getTime() - a[sortBy].date.getTime();
          }
        }

        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy]);
        }

        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    applyFilters() {
      this.updatePagination(this.documentsActivity.mdPage, this.pagination.rowsPerPage);
    },
    downloadReport() {
      this.service.activity.downloadReport('documents', this.filters);
    },
  },
};
</script>

<template>
  <div class="md-layout">
    <md-toolbar md-elevation="0" class="md-transparent">
      <div class="header">
        <router-link to="/statistics">
          <md-button class="md-icon-button">
            <md-icon>arrow_back</md-icon>
          </md-button>
        </router-link>

        <h1 class="md-title">{{ $t('documentsActivityPage.title') }}</h1>
      </div>
      <div>
        <md-button class="md-icon-button" @click="showFilters = !showFilters">
          <md-icon>filter_list</md-icon>
          <md-tooltip md-direction="bottom">Filtra</md-tooltip>
        </md-button>

        <md-button class="md-icon-button" @click="downloadReport">
          <md-icon>download</md-icon>
          <md-tooltip md-direction="bottom">Scarica report attivit&agrave; documenti</md-tooltip>
        </md-button>
      </div>
    </md-toolbar>

    <div class="filters" v-if="showFilters">
      <md-datepicker id="filter-from" v-model="filters.from" md-immediately :md-open-on-focus="false">
        <label>Da</label>
      </md-datepicker>

      <md-datepicker id="filter-to" v-model="filters.to" :md-disabled-dates="disabledDatesForTo" md-immediately
                     :md-open-on-focus="false">
        <label>A</label>
      </md-datepicker>

      <md-field>
        <label for="action">Azioni</label>
        <md-select v-model="filters.actions" name="actions" id="actions" multiple>
          <md-option value="downloaded">SCARICATI</md-option>
          <md-option value="downloaded_as_zip">SCARICATI IN ARCHIVI ZIP</md-option>
          <md-option value="folder_visited">CARTELLE VISITATE</md-option>
        </md-select>
      </md-field>

      <md-field>
        <label for="source">Fonti</label>
        <md-select v-model="filters.sources" name="sources" id="sources" multiple>
          <md-option value="web">WEB</md-option>
          <md-option value="mobile_app">APP</md-option>
        </md-select>
      </md-field>

      <md-field>
        <label>Email</label>
        <md-input v-model="filters.email"></md-input>
      </md-field>

      <md-button class="md-raised md-primary apply-filters-btn" @click="applyFilters">
        Applica
      </md-button>
    </div>

    <div class="md-layout-item md-size-100" v-if="loading">
      <md-progress-bar md-mode="indeterminate"/>
    </div>

    <div class="md-layout" v-if="!loading && documentsActivity.mdData.length === 0">
      <md-empty-state
          md-label="Nessun dato disponibile"
          md-description="Prova a modificare i filtri.">
      </md-empty-state>
    </div>

    <md-table v-if="!loading && documentsActivity.mdData.length > 0" v-model="documentsActivity.mdData"
              :md-sort.sync="currentSort"
              :md-sort-order.sync="currentSortOrder" :md-sort-fn="sortTable" md-card>
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Azione" md-sort-by="action">
          {{ $t(`trackingLabels.action.${item.action}`) }}
        </md-table-cell>
        <md-table-cell md-label="Fonte" md-sort-by="source">
          {{ $t(`trackingLabels.source.${item.source}`) }}
        </md-table-cell>
        <md-table-cell md-label="Data" md-sort-by="performedAt">{{ item.performedAt.label }}</md-table-cell>
        <md-table-cell md-label="Documento">{{ item.document.name }}</md-table-cell>
        <md-table-cell md-label="Utente">{{ `${item.user.firstName} ${item.user.lastName}` }}</md-table-cell>
        <md-table-cell md-label="Codice utente">{{ item.user.code }}</md-table-cell>
        <md-table-cell md-label="Email"><a :href="`mailto:${item.user.email}`">{{ item.user.email }}</a></md-table-cell>
        <md-table-cell md-label="Azienda">{{ item.user.companyName }}</md-table-cell>
      </md-table-row>

      <md-table-pagination
          :md-page-size="pagination.rowsPerPage"
          :md-page-options="[3, 5, 10, 15]"
          :md-update="updatePagination"
          :md-data.sync="documentsActivity"
          :md-label="$t('documentsActivityPage.tablePaginationLabel')"
          :md-separator="$t('documentsActivityPage.tablePaginationSeparator')"/>
    </md-table>
  </div>
</template>

<style scoped lang="scss">
.md-table {
  width: 100%;
  height: 100%;
}

.md-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
}

.filters {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0 2rem;
}

.filters > * {
  width: 12rem;
}

.apply-filters-btn {
  width: 1.5rem;
}
</style>