<template>
	<div class="md-layout">

		<md-toolbar md-elevation="0" class="md-transparent">
			<h1 class="md-title" style="flex: 1">{{ $t('notifications.title') }}</h1>
		</md-toolbar>

		<div class="md-layout-item">
			Work in progress
		</div>

	</div>
</template>

<script>

export default {
	name: 'Notifications',
	data () {
		return {
		}
	}
}
</script>

<style lang="scss">
</style>